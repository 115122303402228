<template>
  <div class="col-md-12">
    <ValidationObserver ref="formNewBoard">
      <div class="row">
        <div v-if="showErrorMessage" class="col-md-12">
          <b-alert show class="mb-2" variant="danger">
            <i class="fa fa-check-circle mr-2"></i>
            <span class="sub-title mr-2">Stages is empty.</span>
          </b-alert>
        </div>
        <div class="col-md-12">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">1</span>Enter Board Details</div>
        </div>
        <div class="col-md-6 u-mb-xsmall">
          <ValidationProvider name="boardName" v-slot="{errors}" rules="required">
            <span class="c-toolbar__state-title u-text-capitalize">Board Name</span>
            <input class="form-control" type="text" v-model="board.name" />
            <span class="text-danger">{{ errors[0] ? "Board Name is Required" : "" }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-6 u-mb-small">
          <span class="c-toolbar__state-title u-text-capitalize">Board Description</span>
          <input class="form-control" type="text" v-model="board.description" />
        </div>

        <div class="col-md-12 mt-3">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">2</span>Assign Stages to Board</div>
        </div>

        <div class="col-md-10  u-mb-small">
          <span class="c-toolbar__state-title u-text-capitalize">Stages</span>
          <v-select value="id" label="displayName" v-model="selectedStage" :options="listStage" :clearable="false"></v-select>
        </div>

        <div class="col-md-2 mt-4">
          <button type="button" class="btn ss-info-bg border-0 btn-secondary btn-md w-100" @click.prevent="addStage"><i class="fa fa-plus"></i> Add Stage</button>
        </div>

        <div class="col-md-12 mt-3">
          <div class="ss--modal-step ss-primary-font-color u-text-bold u-mb-small u-inline-block"><span class="u-mr-small">3</span>Review List of Stages</div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-4 mb-4" v-for="(s, index) in board.stages" :key="index">
              <b-card class="dash-grey-card ">
                <i class="fa fa-times board-remove position-absolute top-0 right-0 mr-3 mt-2 clickable" @click.prevent="removeStage(index)"></i>
                <h5 class="mb-0 font-weight-bold">{{ s.name }}</h5>
                <!-- <small class="d-block">{{ s.description }}</small> -->
                <!-- <div class="badge c-badge--success c-badge--small mr-2" v-for="n in 2" :key="n">selected Stage</div> -->
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
  import MODULE from "@/constants/modules";
  import EVENTS from "@/constants/events";
  import GlobalServices from "@/services/global";
  const global = new GlobalServices();
  export default {
    name: "ManageBoard",
    data() {
      return {
        showErrorMessage: false,
        selectedStage: null,
        listStage: [],
        board: {
          name: null,
          description: null,
          stages: [],
        },
      };
    },
    methods: {
      removeStage(index) {
        if (index > -1) {
          this.board.stages.splice(index, 1);
        }
      },
      addStage() {
        if (!this.selectedStage) return;
        if (this.showErrorMessage) this.showErrorMessage = false;
        this.board.stages.push({
          name: this.selectedStage.displayName,
          // description: this.selectedStage.description // create a new textbox?
        });

        this.selectedStage = null;
      },
      async onSave() {
        const isValid = await this.$refs.formNewBoard.validate();
        if (!isValid || this.board.stages.length === 0) {
          if (this.board.stages.length === 0) {
            this.showErrorMessage = true;
          }
          return;
        } else {
          // send payload
          // this.board
        }
      },
      async init() {
        this.listStage = await global.getUXDropDown(MODULE.LEADS.UX.LeadStages);
      },
    },
    mounted() {
      this.init();
    },
    created() {
      this.EVENT_ACTION = EVENTS;
      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.LEADS.ManageBoard, this.onSave);
    },
    beforeDestroy() {
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.LEADS.ManageBoard, this.onSave);
    },
  };
</script>
<style>
  .planner-swatch .vue-swatches__trigger {
    width: 60px !important;
  }
  .stage-border {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0px 0px 4px 4px !important;
  }
</style>
